
// Create loading element for before app initialization
export const createLoadingElement = () => {
  // Skip creating a loading element since it's already in index.html
  console.log('Loading element already exists in HTML, skipping creation');
};

// Function to safely remove the loading element
export const removeLoadingElement = () => {
  try {
    const loadingEl = document.getElementById('root-loading');
    if (!loadingEl) {
      console.log('No loading element found to remove');
      return;
    }
    
    console.log('Removing loading element');
    
    // First fade it out using CSS
    loadingEl.classList.add('fade-out');
    
    // Use a longer timeout to ensure all resources are loaded
    setTimeout(() => {
      try {
        // Check if the element is still in the DOM
        if (!document.body.contains(loadingEl)) {
          console.log('Loading element already removed from DOM');
          return;
        }
        
        // Try standard removal if it has a parent
        if (loadingEl.parentNode) {
          loadingEl.parentNode.removeChild(loadingEl);
          console.log('Loading element removed successfully');
        } else {
          // If no parent, just hide it
          loadingEl.style.display = 'none';
          console.log('Loading element hidden (no parent node)');
        }
      } catch (removeError) {
        console.error('Error during loading element removal:', removeError);
        // Fallback - hide with CSS
        try {
          loadingEl.style.display = 'none';
          console.log('Loading element hidden with CSS as fallback');
        } catch (cssError) {
          console.error('Failed to hide loading element with CSS:', cssError);
        }
      }
    }, 800); // Increased timeout for smoother transition
  } catch (error) {
    console.error('Error in removeLoadingElement:', error);
  }
};

// Function to check if React has successfully initialized
export const checkReactInitialized = () => {
  try {
    const rootElement = document.getElementById('root');
    return !!rootElement && rootElement.hasChildNodes() && rootElement.children.length > 0;
  } catch (error) {
    console.error('Error checking if React is initialized:', error);
    return false;
  }
};
