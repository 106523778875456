
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { supabase } from '@/integrations/supabase/client';
import type { Language } from '@/contexts/LanguageContext';

// Function to fetch translations from Supabase
const loadTranslations = async (language: Language) => {
  try {
    console.log(`Loading translations for language: ${language}`);
    
    const { data, error } = await supabase
      .from('translations')
      .select('*')
      .eq('language', language);

    if (error) {
      console.error('Error loading translations:', error);
      return {};
    }

    console.log(`Raw translations data for ${language}:`, data);

    // Transform the data into the format i18next expects
    const transformedData = data.reduce((acc: Record<string, any>, curr) => {
      if (!acc[curr.namespace]) {
        acc[curr.namespace] = {};
      }
      acc[curr.namespace][curr.key] = curr.value;
      return acc;
    }, {});

    console.log(`Transformed translations for ${language}:`, transformedData);
    return transformedData;
  } catch (error) {
    console.error('Error in loadTranslations:', error);
    return {};
  }
};

// Initialize i18next with minimal configuration first
i18n.use(initReactI18next).init({
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      common: {
        // Add some default common translations as fallbacks
        loading: 'Loading...',
        error: 'An error occurred',
        try_again: 'Try Again'
      },
      home: {},
      services: {},
      pricing: {},
      hero: {}
    }
  },
  defaultNS: 'common',
  ns: ['common', 'home', 'services', 'pricing', 'hero'],
  react: {
    useSuspense: false
  },
  // Add missing key handler to log missing translations instead of showing empty
  saveMissing: true,
  missingKeyHandler: (lng, ns, key) => {
    console.warn(`Missing translation: ${key} in namespace ${ns} for language ${lng}`);
  },
  // Return key if value is missing (more readable for end users)
  returnNull: false,
  returnEmptyString: false,
  returnObjects: true,
  parseMissingKeyHandler: (key) => {
    return key.split('.').pop() || key;
  }
});

// Function to change language
export const changeLanguage = async (language: Language) => {
  try {
    console.log(`Changing language to: ${language}`);
    const translations = await loadTranslations(language);
    
    Object.entries(translations).forEach(([namespace, resources]) => {
      i18n.addResourceBundle(language, namespace, resources, true, true);
    });
    
    await i18n.changeLanguage(language);
    
    console.log(`Language changed successfully to: ${language}`);
    console.log('Current i18n resources:', i18n.services.resourceStore.data);
  } catch (error) {
    console.error('Error changing language:', error);
    // Fallback to English on error
    if (language !== 'en') {
      console.log('Falling back to English due to error');
      i18n.changeLanguage('en');
    }
  }
};

// Load initial English translations
loadTranslations('en').then((translations) => {
  Object.entries(translations).forEach(([namespace, resources]) => {
    i18n.addResourceBundle('en', namespace, resources, true, true);
  });
});

export default i18n;
