
import React, { Component, ErrorInfo, ReactNode } from "react";

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null
    };
  }

  static getDerivedStateFromError(error: Error): Pick<State, 'hasError' | 'error'> {
    // Update state so the next render will show the fallback UI
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // Log the error to help with debugging
    console.error("🛑 React Error Boundary caught an error:", error);
    console.error("Component Stack:", errorInfo.componentStack);
    
    // Save error info for display
    this.setState({ errorInfo });
    
    // Log more details to help with debugging
    if (error.stack) {
      console.error("Error stack:", error.stack);
    }
    
    // Check if this is a vendor bundle error
    const isVendorError = error.stack && error.stack.includes('vendor');
    if (isVendorError) {
      console.error("Vendor bundle error detected in error boundary!");
    }
  }

  resetError = () => {
    this.setState({ hasError: false, error: null, errorInfo: null });
  };

  render(): ReactNode {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="min-h-screen flex flex-col items-center justify-center p-4 bg-gray-50">
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full text-center">
            <h2 className="text-2xl font-bold text-red-600 mb-4">Something went wrong</h2>
            <p className="text-gray-700 mb-4">
              {this.state.error?.message || "An unexpected error occurred"}
            </p>
            {this.state.errorInfo && (
              <details className="mb-4 text-left">
                <summary className="cursor-pointer text-blue-600 hover:underline mb-2">
                  View technical details
                </summary>
                <pre className="bg-gray-100 p-4 rounded text-xs overflow-auto max-h-60">
                  {this.state.error?.toString()}
                  <br />
                  {this.state.errorInfo.componentStack}
                </pre>
              </details>
            )}
            <div className="flex flex-col sm:flex-row justify-center gap-3">
              <button
                className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                onClick={() => window.location.href = '/'}
              >
                Go to Home
              </button>
              <button
                className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={() => {
                  this.resetError();
                  window.location.reload();
                }}
              >
                Try Again
              </button>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
