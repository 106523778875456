
import React from 'react';

// Global unhandled error handler with detailed logging
export const setupErrorHandlers = () => {
  // Add error event listener
  window.addEventListener('error', (event) => {
    console.error('🔴 GLOBAL ERROR:', event.error);
    console.error('Error message:', event.message);
    console.error('Error source:', event.filename, 'Line:', event.lineno, 'Column:', event.colno);
    
    // Prevent undefined errors more aggressively - this is crucial for vendor bundle issues
    if (event.message && (
        event.message.includes('undefined has no properties') || 
        event.message.includes('null is not an object') ||
        event.message.includes('cannot read property') ||
        event.message.includes('is not defined') ||
        event.message.includes('Cannot read') ||
        event.message.includes('is not a function')
      )) {
      console.warn('Prevented undefined error. This is likely a timing issue with dependencies loading.');
      event.preventDefault();
      return true; // Prevent the error from propagating
    }
    
    // More detailed stack logging for debugging
    if (event.error && event.error.stack) {
      console.error('Error stack trace:', event.error.stack);
      
      // Log individual stack frames for better debugging
      const stackFrames = event.error.stack.split('\n');
      console.error('Stack frames:');
      stackFrames.forEach((frame, index) => {
        console.error(`Frame ${index}:`, frame.trim());
      });
    }
    
    // Check for vendor bundle errors specifically
    if (event.filename && event.filename.includes('vendor')) {
      console.error('Vendor bundle error detected! This might be a dependency initialization issue.');
      // Prevent these errors from breaking the application
      if (event.message.includes('undefined has no properties')) {
        event.preventDefault();
        return true;
      }
    }
    
    // Check if this is happening during initial render
    console.error('DOM ready state:', document.readyState);
    
    // Don't show error UI for undefined errors that might resolve themselves
    if (!event.message || !event.message.includes('undefined has no properties')) {
      showErrorUI(event);
    }
    
    return false; // Let other error handlers run
  });

  // Add unhandledrejection handler for Promise errors with improved logging
  window.addEventListener('unhandledrejection', (event) => {
    console.error('🔴 UNHANDLED PROMISE REJECTION:', event.reason);
    
    // Prevent undefined errors specifically
    if (event.reason && event.reason.message && (
        event.reason.message.includes('undefined has no properties') ||
        event.reason.message.includes('null is not an object') ||
        event.reason.message.includes('cannot read property') ||
        event.reason.message.includes('is not defined') ||
        event.reason.message.includes('Cannot read')
      )) {
      console.warn('Prevented undefined promise rejection. This is likely a timing issue with dependencies loading.');
      event.preventDefault();
      return true; // Prevent the error from propagating
    }
    
    // Detailed promise rejection logging
    if (event.reason && event.reason.stack) {
      console.error('Promise rejection stack:', event.reason.stack);
      
      // Log individual stack frames
      const stackFrames = event.reason.stack.split('\n');
      console.error('Promise rejection stack frames:');
      stackFrames.forEach((frame, index) => {
        console.error(`Frame ${index}:`, frame.trim());
      });
    }
    
    // Try to identify the source of the rejection
    console.error('Promise rejection source:', event.reason?.source || 'unknown');
  });

  // Early initialization safety measures
  document.addEventListener('DOMContentLoaded', () => {
    console.log('DOM fully loaded and parsed');
  });
};

// Function to display error UI when something goes wrong
export const showErrorUI = (event: ErrorEvent | any) => {
  // Only show error UI if the app hasn't fully loaded yet
  const rootElement = document.getElementById('root');
  if (rootElement && (!rootElement.hasChildNodes() || rootElement.children.length === 0)) {
    rootElement.innerHTML = `
      <div style="padding: 20px; text-align: center; font-family: system-ui, sans-serif;">
        <h2 style="color: #d32f2f;">Something went wrong</h2>
        <p>We're sorry, but the application couldn't load properly.</p>
        <p style="font-size: 0.8em; color: #666; margin-top: 10px; white-space: pre-wrap; text-align: left; 
                  background: #f5f5f5; padding: 10px; border-radius: 4px; max-width: 800px; margin: 10px auto; 
                  overflow-x: auto;">
          Error: ${event.message || (event instanceof Error ? event.message : 'Unknown error')}
          Location: ${event.filename || 'unknown'} ${event.lineno ? `(line ${event.lineno}, column ${event.colno})` : ''}
        </p>
        <button onclick="window.location.reload()" 
                style="background: #04627a; color: white; border: none; padding: 8px 16px; 
                       border-radius: 4px; cursor: pointer; margin-top: 16px;">
          Refresh the page
        </button>
      </div>
    `;
  }
};
