
import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import App from '../App';
import ErrorBoundary from '../components/ErrorBoundary';
import { showErrorUI } from './errorHandlers';
import { removeLoadingElement, checkReactInitialized } from './loadingIndicator';

// Flag to track if we've attempted to remove the loading element
let loadingElementRemovalAttempted = false;
// Flag to track if React has been initialized
let reactInitialized = false;

// Initialize React application
export const initializeReact = () => {
  try {
    // If React is already initialized, don't try again
    if (reactInitialized) {
      console.log('React has already been initialized, skipping');
      return;
    }
    
    console.log('Starting application initialization...');
    
    // Ensure window.React is defined before rendering
    if (typeof window !== 'undefined') {
      if (!window.React) {
        console.warn('React not found on window, assigning it now');
        window.React = React;
      }
      if (!window.ReactDOM) {
        console.warn('ReactDOM not found on window, assigning it now');
        window.ReactDOM = ReactDOM;
      }
    }
    
    const rootElement = document.getElementById('root');
    if (!rootElement) {
      console.error('Root element not found in the DOM');
      if (!loadingElementRemovalAttempted) {
        loadingElementRemovalAttempted = true;
        removeLoadingElement();
      }
      return;
    }
    
    console.log('Root element found, creating React root...');
    
    try {
      // Check if React is already initialized
      if (checkReactInitialized()) {
        console.log('React already appears to be initialized, skipping render');
        reactInitialized = true;
        if (!loadingElementRemovalAttempted) {
          loadingElementRemovalAttempted = true;
          removeLoadingElement();
        }
        return;
      }
      
      // Create the root with a try-catch to catch any initialization errors
      let root;
      try {
        // Make absolutely sure React is available
        if (typeof window !== 'undefined') {
          window.React = React;
          window.ReactDOM = ReactDOM;
        }
        
        // Ensure there are no existing child elements in the root
        while (rootElement.firstChild) {
          rootElement.removeChild(rootElement.firstChild);
        }
        
        root = createRoot(rootElement);
        console.log('React root created successfully');
      } catch (rootError) {
        console.error('Failed to create React root:', rootError);
        // Try one more time with a direct import
        if (typeof window !== 'undefined') {
          window.React = React;
          window.ReactDOM = ReactDOM;
        }
        
        // Legacy fallback for older browsers
        try {
          console.log('Attempting legacy render as fallback...');
          ReactDOM.render(
            React.createElement(
              React.StrictMode,
              null,
              React.createElement(
                ErrorBoundary,
                null,
                React.createElement(App, null)
              )
            ),
            rootElement
          );
          console.log('Legacy render successful');
          reactInitialized = true;
          
          // Signal to index.html that app has started
          if (typeof window !== 'undefined' && window.appStarted) {
            window.appStarted();
          }
          return;
        } catch (legacyError) {
          console.error('Legacy render also failed:', legacyError);
          throw rootError; // Re-throw the original error
        }
      }
      
      if (!root) {
        throw new Error('Failed to create React root');
      }
      
      console.log('Rendering application...');
      // Use createElement explicitly to avoid JSX transpilation issues
      root.render(
        React.createElement(
          React.StrictMode,
          null,
          React.createElement(
            ErrorBoundary,
            null,
            React.createElement(App, null)
          )
        )
      );
      
      // Mark as initialized
      reactInitialized = true;
      console.log('Application rendered successfully');
      
      // Signal to index.html that app has started
      if (typeof window !== 'undefined' && window.appStarted) {
        console.log('Calling appStarted signal');
        window.appStarted();
      } else {
        console.warn('appStarted function not found on window');
        // Still attempt to remove loading element
        if (!loadingElementRemovalAttempted) {
          loadingElementRemovalAttempted = true;
          removeLoadingElement();
        }
      }
      
      // Double-check to ensure loading indicator is removed
      setTimeout(() => {
        if (!loadingElementRemovalAttempted) {
          loadingElementRemovalAttempted = true;
          removeLoadingElement();
        }
      }, 1000);
    } catch (renderError) {
      console.error('Failed during React root creation or rendering:', renderError);
      console.error('Error details:', {
        message: renderError.message,
        name: renderError.name,
        stack: renderError.stack
      });
      
      // Try to remove the loading element even if there was an error
      if (!loadingElementRemovalAttempted) {
        loadingElementRemovalAttempted = true;
        removeLoadingElement();
      }
      
      // Show a fallback UI in case of render failure
      if (rootElement) {
        rootElement.innerHTML = `
          <div style="padding: 20px; text-align: center; font-family: system-ui, sans-serif;">
            <h2 style="color: #d32f2f;">React Rendering Error</h2>
            <p>We encountered an issue while rendering the application.</p>
            <p style="color: #666; font-size: 0.9em;">${renderError instanceof Error ? renderError.message : 'Unknown error'}</p>
            <button onclick="window.location.reload()" 
                    style="background: #04627a; color: white; border: none; padding: 8px 16px; 
                           border-radius: 4px; cursor: pointer; margin-top: 16px;">
              Try Again
            </button>
          </div>
        `;
      }
    }
  } catch (error) {
    console.error('Failed to render the application (outer try-catch):', error);
    console.error('Error type:', typeof error);
    console.error('Error details:', {
      message: error?.message,
      name: error?.name,
      stack: error?.stack
    });
    
    // Try to remove the loading element even if there was an error
    if (!loadingElementRemovalAttempted) {
      loadingElementRemovalAttempted = true;
      removeLoadingElement();
    }
    
    showErrorUI(error);
  }
};
