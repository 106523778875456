
// Import the global type definition
import './types/window';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { setupErrorHandlers } from './utils/errorHandlers';
import { initializeReact } from './utils/reactInitializer';

// Make React available globally FIRST - this is crucial
if (typeof window !== 'undefined') {
  window.React = React;
  window.ReactDOM = ReactDOM;
  console.log('React assigned to window object');
}

// Set up global error handlers
setupErrorHandlers();

// Track initialization to prevent multiple attempts
let appInitialized = false;

// Function to check if all required dependencies are loaded
const checkDependencies = () => {
  if (typeof window === 'undefined') {
    console.warn('Window not available');
    return false;
  }
  
  if (!window.React) {
    console.warn('React not yet available on window object');
    window.React = React;
    return false;
  }
  
  return true;
};

// Initialize React application with safety checks
const safelyInitializeReact = () => {
  // If already initialized, don't try again
  if (appInitialized) {
    console.log('App already initialized, skipping');
    return;
  }
  
  console.log('Checking dependencies before initialization...');
  
  if (checkDependencies()) {
    console.log('Dependencies ready, initializing React application...');
    // Set React on window object again to ensure it's available
    if (typeof window !== 'undefined') {
      window.React = React;
      window.ReactDOM = ReactDOM;
    }
    appInitialized = true;
    
    // Wrap initialization in try-catch to prevent white screens
    try {
      initializeReact();
    } catch (error) {
      console.error('Failed to initialize React application:', error);
      
      // Emergency fallback - display error message if initialization fails
      const rootElement = document.getElementById('root');
      if (rootElement && (!rootElement.hasChildNodes() || rootElement.children.length === 0)) {
        rootElement.innerHTML = `
          <div style="padding: 20px; text-align: center; font-family: system-ui, sans-serif;">
            <h2 style="color: #d32f2f;">Application Error</h2>
            <p>We encountered an issue while starting the application.</p>
            <p style="color: #666; font-size: 0.9em;">${error instanceof Error ? error.message : 'Unknown error'}</p>
            <button onclick="window.location.reload()" 
                    style="background: #04627a; color: white; border: none; padding: 8px 16px; 
                          border-radius: 4px; cursor: pointer; margin-top: 16px;">
              Try Again
            </button>
          </div>
        `;
      }
    }
  } else {
    console.warn('Dependencies not ready yet, retrying in 100ms...');
    setTimeout(safelyInitializeReact, 100);
  }
};

// Improved initialization with multiple fallback strategies
const startApp = () => {
  console.log('Starting initialization sequence...');
  // Triple-ensure React is available on window
  if (typeof window !== 'undefined') {
    window.React = React;
    window.ReactDOM = ReactDOM;
  }

  safelyInitializeReact();
};

// Use DOMContentLoaded instead of unload (which is deprecated)
document.addEventListener('DOMContentLoaded', () => {
  console.log('DOM content loaded event triggered');
  // Ensure React is available
  if (typeof window !== 'undefined') {
    window.React = React;
    window.ReactDOM = ReactDOM;
  }
  setTimeout(startApp, 50);
});

// Immediate check for already loaded document
if (document.readyState === 'complete' || document.readyState === 'interactive') {
  console.log(`Document already in state: ${document.readyState}, starting app directly`);
  // Ensure React is available
  if (typeof window !== 'undefined') {
    window.React = React;
    window.ReactDOM = ReactDOM;
  }
  setTimeout(startApp, 50);
}

// Fallback with timeout
setTimeout(() => {
  console.log('Fallback initialization triggered');
  if (!appInitialized) {
    if (typeof window !== 'undefined') {
      window.React = React;
      window.ReactDOM = ReactDOM;
    }
    safelyInitializeReact();
  }
}, 300);

// Final safety net - if page is still white after 2 seconds
setTimeout(() => {
  const rootElement = document.getElementById('root');
  if (!appInitialized || !rootElement?.hasChildNodes() || rootElement.children.length === 0) {
    console.warn('Emergency fallback: Application failed to render after 2s');
    
    // Force re-render the application with extra precautions
    // Make absolutely sure React is defined
    if (typeof window !== 'undefined') {
      if (!window.React) {
        window.React = React;
        console.warn('Re-assigned React to window object in emergency fallback');
      }
      if (!window.ReactDOM) {
        window.ReactDOM = ReactDOM;
        console.warn('Re-assigned ReactDOM to window object in emergency fallback');
      }
    }
    
    try {
      appInitialized = true;
      initializeReact();
    } catch (error) {
      console.error('Emergency initialization failed:', error);
      
      // If we still can't initialize React, show a fallback UI
      if (rootElement) {
        rootElement.innerHTML = `
          <div style="padding: 20px; text-align: center; font-family: system-ui, sans-serif;">
            <h2 style="color: #04627a;">Loading Shahhost...</h2>
            <p>The application is taking longer than expected to load.</p>
            <p style="color: #666; font-size: 0.9em;">
              Technical details: ${error instanceof Error ? error.message : 'Failed to initialize React'}
            </p>
            <button onclick="window.location.reload()" 
                    style="background: #04627a; color: white; border: none; padding: 8px 16px; 
                           border-radius: 4px; cursor: pointer; margin-top: 16px;">
              Refresh the page
            </button>
          </div>
        `;
      }
    }
  }
}, 2000);
