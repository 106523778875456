
import React, { createContext, useContext, useState, useEffect } from 'react';
import { changeLanguage } from '@/i18n/config';
import { useToast } from '@/hooks/use-toast';

export type Language = 'en' | 'dr' | 'ps';

interface LanguageContextType {
  currentLanguage: Language;
  setLanguage: (lang: Language) => Promise<void>;
}

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

export const LanguageProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [currentLanguage, setCurrentLanguage] = useState<Language>('en');
  const [isInitialized, setIsInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { toast } = useToast();

  const setLanguage = async (lang: Language) => {
    try {
      setIsLoading(true);
      await changeLanguage(lang);
      setCurrentLanguage(lang);
      localStorage.setItem('preferredLanguage', lang);
      
      // Only show toast if it's an active language change (not initial load)
      if (isInitialized && lang !== localStorage.getItem('preferredLanguage')) {
        toast({
          title: "Language Changed",
          description: `Successfully switched to ${lang.toUpperCase()}`,
        });
      }
    } catch (error) {
      console.error('Error changing language:', error);
      if (isInitialized) {
        toast({
          title: "Error",
          description: "Failed to change language, using fallback",
          variant: "destructive",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const initializeLanguage = async () => {
      try {
        const savedLanguage = localStorage.getItem('preferredLanguage') as Language | null;
        await setLanguage(savedLanguage || 'en');
      } catch (error) {
        console.error('Failed to initialize language:', error);
        // Continue with English as fallback
        setCurrentLanguage('en');
      } finally {
        setIsInitialized(true);
        setIsLoading(false);
      }
    };

    initializeLanguage();
  }, []);

  if (isLoading && !isInitialized) {
    return <div className="flex items-center justify-center h-screen">Initializing...</div>;
  }

  return (
    <LanguageContext.Provider value={{ currentLanguage, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};
